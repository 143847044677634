import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import logo from '../assets/images/logo.svg'
import arrowIcon from '../assets/images/icons/down-arrow-black-icon.svg'

import ProductIcon1 from '../assets/images/product-img-1.svg'
import ProductIcon2 from '../assets/images/product-img-2.svg'
import ProductIcon3 from '../assets/images/product-img-3.svg'
import ProductIcon4 from '../assets/images/product-img-4.svg'
import ProductIcon5 from '../assets/images/product-img-5.svg'
import ProductIcon6 from '../assets/images/product-img-6.svg'
import ProductIcon7 from '../assets/images/product-img-7.svg'
import ProductIcon8 from '../assets/images/product-img-8.svg'
import ProductIcon9 from '../assets/images/product-img-9.svg'
import ProductIcon10 from '../assets/images/product-img-10.svg'



function Header() {

    const [menuOpen, setMenuOpen] = useState(false)
    const [stickyClass, setStickyClass] = useState(false)
    const onMenuOpen = () =>{
        setMenuOpen(true);
    }
    const onMenuClose = () =>{
        setMenuOpen(false);
    }

    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });

    const isSticky = (e) => {
        // const header = document.querySelector('.header-section');
        const scrollTop = window.scrollY;
        // console.log(scrollTop)
        if (scrollTop > 80){
            setStickyClass(true)
        }else {
            setStickyClass(false)
        }

        // scrollTop >= 250 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
    };

    return (
        <>
            <header className={`header ${stickyClass? 'sticky' : ''}`}>
                <div className="top-header">
                    <div className="container">
                        <ul className="menu">
                            <li><a href="#" className="semi gray">Support</a></li>
                            <li><a href="#" className="semi gray">Blog</a></li>
                            <li><Link to="/about" className="semi gray">About</Link></li>
                            <li><a href="https://t.me/YardTools" target="_blank" className="semi gray">Contact Us</a></li>
                        </ul>
                    </div>
                </div>
                <div className="bottom-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3 col-6 align-self-center">
                                <Link to="/" className="logo">
                                    <img src={logo} alt="logo" />
                                </Link>
                            </div>
                            <div className="col-md-9 col-6 align-self-center">
                                <div className={`menu-wrapper ${menuOpen ? 'active' : ''}`}>
                                    <div className="close-menu">
                                        <i className="fa-solid fa-x" onClick={onMenuClose}></i>
                                    </div>
                                    <ul className="menu">
                                        <li>
                                            <a href="#" className="semi"><span>Products</span> <img src={arrowIcon} alt="icon"/></a>
                                            <div className="mega-menu">
                                                <div className="mega-menu-wrapper">
                                                    <h3>TOP PRODUCTS</h3>
                                                    <ul className="products">
                                                        <li>
                                                            <Link to="/volume-trader">
                                                                <div className="img-wrapper">
                                                                    <img src={ProductIcon1} alt="icon"/>
                                                                </div>
                                                                <span className="content">
                                                                <p className="p">Volume Trader</p>
                                                                <p>Scans on chain activities and enables users to track the flow of liquidity in real-time</p>
                                                            </span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/volume-trader">
                                                                <div className="img-wrapper">
                                                                    <img src={ProductIcon2} alt="icon"/>
                                                                </div>
                                                                <span className="content">
                                                                <p className="p">Decompiler</p>
                                                                <p>Uncovering prospective insider coins and mooner tokens</p>
                                                            </span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/volume-trader">
                                                                <div className="img-wrapper">
                                                                    <img src={ProductIcon3} alt="icon"/>
                                                                </div>
                                                                <span className="content">
                                                                <p className="p">Explorer</p>
                                                                <p>Lookup token stats in real time right inside of your telegram groups</p>
                                                            </span>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <a href="#" className="semi"><span>Company</span> <img src={arrowIcon} alt="icon"/></a>
                                            <div className="mega-menu">
                                                <div className="mega-menu-wrapper">
                                                    <h3>COMPANY</h3>
                                                    <ul className="products">
                                                        <li>
                                                            <Link to="/about">
                                                                <div className="img-wrapper">
                                                                    <img src={ProductIcon4} alt="icon"/>
                                                                </div>
                                                                <span className="content">
                                                                <p className="p">About</p>
                                                                <p>Everything you need to know about YardTools</p>
                                                            </span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/about#team">
                                                                <div className="img-wrapper">
                                                                    <img src={ProductIcon5} alt="icon"/>
                                                                </div>
                                                                <span className="content">
                                                                <p className="p">Team</p>
                                                                <p>YardTools founding members and developers</p>
                                                            </span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                <div className="img-wrapper">
                                                                    <img src={ProductIcon6} alt="icon"/>
                                                                </div>
                                                                <span className="content">
                                                                <p className="p">Partners</p>
                                                                <p>Explore our network of esteemed partner organizations</p>
                                                            </span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <a href="#" className="semi"><span>Resources</span> <img src={arrowIcon} alt="icon"/></a>
                                            <div className="mega-menu">
                                                <div className="mega-menu-wrapper">
                                                    <h3>RESOURCES</h3>
                                                    <ul className="products">
                                                        <li>
                                                            <a href="https://docs.google.com/forms/d/e/1FAIpQLScecTqKyH_b-L2J-tg5t4EbHQJiY8D6oxMtxlRq1G7Z_klrDw/viewform" target="_blank">
                                                                <div className="img-wrapper">
                                                                    <img src={ProductIcon7} alt="icon"/>
                                                                </div>
                                                                <span className="content">
                                                                <p className="p">Feedback</p>
                                                                <p>Have suggestions, complaints, or criticisms? We'd love to hear from you</p>
                                                            </span>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                <div className="img-wrapper">
                                                                    <img src={ProductIcon8} alt="icon"/>
                                                                </div>
                                                                <span className="content">
                                                                <p className="p">FAQ’s</p>
                                                                <p>Discover answers to commonly asked questions about our bots</p>
                                                            </span>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://t.me/YardToolsSupport_Bot" target="_blank">
                                                                <div className="img-wrapper">
                                                                    <img src={ProductIcon9} alt="icon"/>
                                                                </div>
                                                                <span className="content">
                                                                <p className="p">Help Center</p>
                                                                <p>Reach out to us via our Telegram support bot for prompt help</p>
                                                            </span>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                <div className="img-wrapper">
                                                                    <img src={ProductIcon10} alt="icon"/>
                                                                </div>
                                                                <span className="content">
                                                                <p className="p">Legal Agreements</p>
                                                                <p>Access essential legal information and resources related to our services</p>
                                                            </span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                        <li><a href="https://dashboard.theyard.tools/" target="_blank" className="semi"><span>Dashboard</span></a></li>
                                        <li><a href="https://t.me/YardTools" target="_blank" className="btn-style">JOIN TELEGRAM</a></li>
                                    </ul>
                                </div>
                                <div className="hamburger-menu">
                                    <i className="fa-solid fa-bars" onClick={onMenuOpen}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}

export default Header;
