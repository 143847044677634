import {useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import "./assets/scss/main.scss";

import AOS from 'aos';
import 'aos/dist/aos.css';

import {Routes, Route} from "react-router-dom";

import HomeView from "./pages/HomePage";
import VolumeTracker from "./pages/VolumeTracker";
import AboutPage from "./pages/AboutPage";


function App() {

    useEffect(() => {
        AOS.init({once: true});
    }, [])

  return (
      <div className="App">
        <Routes>
          <Route path="/" element={<HomeView/>}/>
          <Route path="/volume-trader" element={<VolumeTracker/>}/>
          <Route path="/about" element={<AboutPage/>}/>
        </Routes>
      </div>
  )
}

export default App;
