import React, {useState, useEffect} from 'react';

import yardImg1 from "../assets/images/yard-card-img-1.png";
import yardImg2 from "../assets/images/yard-card-img-2.png";
import yardImg3 from "../assets/images/yard-card-img-3.png";

function YardTools() {

    const tools = [
        {
            img: yardImg1,
            title: 'Easy to setup and use',
            desc: 'Get up and running in\n' +
                '                                    minutes with customizable crypto tools.'
        },
        {
            img: yardImg2,
            title: 'Everything in one place',
            desc: 'Get insights from multiple\n' +
                '                                    data points in just one place'
        },
        {
            img: yardImg3,
            title: 'Happy Users',
            desc: 'Reliable telegram bots with\n' +
                '                                    little to no downtime'
        },
    ]

    return (
        <>
            {
                tools.map((item, index)=>(
                    <div className="yard-card" key={index} data-aos="fade-up" data-aos-delay={(index + 1) * 100}>
                        <div className="img-wrapper">
                            <img src={item.img} alt="img"/>
                        </div>
                        <div className="content-wrapper">
                            <h3>{item.title}</h3>
                            <p className="p">{item.desc}</p>
                        </div>
                    </div>
                ))
            }
        </>
    );
}

export default YardTools;
