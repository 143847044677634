import React, {useState, useEffect} from 'react';
import { useLocalStorage } from "../useLocalStorage";

// Import Components
import Header from "../components/Header";
import Footer from "../components/Footer";
import CryptoTrading from "../components/CryptoTrading";
import OurFaq from "../components/OurFaq";

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Accordion from 'react-bootstrap/Accordion';

// Import Images
import volumeBannerImg from '../assets/images/volume-trader-banner-img.png'
import rightArrow from '../assets/images/icons/blue-right-arrow.svg'
import notificationsImg from '../assets/images/customizable-notifications-img.png'

function VolumeTracker() {
    const [referralId, setReferralId] = useLocalStorage("referralId", "");
    const [channelName, setChannelName] = useState('')
    const [randomNumber, setRandomNumber] = useState(null);
    const [hasGenerated, setHasGenerated] = useState(false);
    // Referral id from url
    const search = window.location.search;
    const params = new URLSearchParams(search);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    useEffect(() => {
        getChannel();
        const utmReferralId = params.get('utm_referral_id');
        if (utmReferralId) {
            setReferralId(utmReferralId);
        }
    }, [channelName, hasGenerated]);

    // Get available bots
    const getChannel = async () => {
        try {
            const response = await fetch(`https://api.theyard.tools/available-bots`, {
                method: 'GET',
                // headers: {},
            });
            const json = await response.json();
            if (randomNumber) {
                setChannelName(json[randomNumber].replace('@', ''));
            }
            if (!hasGenerated) {
                var min = Math.ceil(1);
                var max = Math.floor(13);
                const newRandomNumber = Math.floor(Math.random()  * (max - min + 1)) + min; // Change 100 to your desired range
                setRandomNumber(newRandomNumber);
                setHasGenerated(true);
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    return (
        <div className="home">

            <Header/>

            <section className="banner inner-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 align-self-center">
                            <div className="content-wrapper">
                                <p className="tag" data-aos="fade-up">Gain Insight With</p>
                                <h1 data-aos="fade-up"><span className="line">Volume</span> Trader</h1>
                                <p className="large" data-aos="fade-up" data-aos-delay="100">Conquer the market with this efficient bot, providing real-time insights on tokens across blockchains.</p>
                                <ul className="flex-column flex-lg-row" data-aos="fade-up" data-aos-delay="200">
                                    <li><a href="#plans" className="btn-style">GET STARTED FOR FREE</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6 align-self-center">
                            <div className="img-wrapper">
                                <img src={volumeBannerImg} alt="img"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="token-volume">
                <div className="container">
                    <ul className="bread-crumb">
                        <li><a href="#" className="large">Products</a></li>
                        <li><p>Volume Trader</p></li>
                    </ul>
                    <div className="content-wrapper">
                        <h2>Token volume inside of telegram</h2>
                        <p className="gray">Volume Trader was conceived by a consortium of seasoned traders,
                            each an expert in their respective markets. These professionals
                            sought to refine their trading methodology, and in doing so, they
                            engineered a suite of innovative tools. Their objective was clear:
                            to enhance their trading efficiency and to bolster their success
                            rates to levels not previously seen in their trading circles. The
                            result of their efforts was a robust system that revolutionized their
                            approach to trading.</p>
                        <p className="gray">The cornerstone of Volume Trader lies in its meticulous analysis of
                            market data, with a particular focus on volume fluctuations. The traders
                            understood that volume often precedes price movements, making it an
                            invaluable indicator of potential market shifts. To capitalize on this,
                            they developed a sophisticated filtration system, designed to sift through
                            the noise and identify the signals that matter most. This system leverages
                            cutting-edge technology to parse through data at an unprecedented speed,
                            ensuring that users receive timely and accurate insights.</p>
                        <p className="gray">At its core, Volume Trader is more than just a bot; it is the embodiment
                            of a strategy that prioritizes early detection of market opportunities.
                            It is programmed to scout the horizon for nascent tokens that exhibit the
                            hallmarks of growth and to bring them to the attention of its users swiftly.</p>
                        <a href="https://yardtools.gitbook.io/suite/volume-trader/abstract" target="_blank" className="btn-style medium transparent round icon blue-color btn-style-link text-truncate" data-aos="fade-up">
                            Stay on top of tokens volume with Volume Trader <img src={rightArrow} alt="icon"/></a>
                    </div>
                </div>
            </section>

            <section className="customizable-notifications background-alt">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7 order-2 order-md-1 align-self-center">
                            <div className="content-wrapper">
                                <h2>Customizable Notifications</h2>
                                <p className="gray">Whether you're tracking volume surges, liquidity
                                    changes, or market cap milestones, our bot is your dedicated lookout.
                                    It filters the noise of the market to bring you actionable alerts,
                                    allowing you to stay ahead of the curve and make decisions with
                                    confidence. With Yardtools, you're not just following the
                                    market—you're engaging with it on your terms.</p>
                                <p className="gray">Take control of your trading experience with
                                    Yardtools' Volume Trader bot, designed to align with your
                                    personal trading criteria.</p>
                                <a href="https://yardtools.gitbook.io/suite/volume-trader/guides/notifications" target="_blank" className="btn-style medium transparent round icon blue-color btn-style-link text-truncate" data-aos="fade-up">
                                    Customizable notifications to meet your needs <img src={rightArrow} alt="icon"/></a>

                            </div>
                        </div>
                        <div className="col-md-5 order-1 order-md-2 align-self-center">
                            <div className="img-wrapper ps-lg-5 ms-lg-5">
                                <img src={notificationsImg} alt="img" data-aos="fade-left"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pricing-plan" id="plans">
                <div className="container">
                    <h2>Choose the best plan for you</h2>
                    <ul className="trial">
                        <li>1 day free trial</li>
                        <li>No payment required</li>
                    </ul>
                    <div className="tabs-wrapper">
                        <Tab.Container defaultActiveKey="first">
                            <div className="tabs-header">
                                <div className="d-flex justify-content-center">
                                <Nav variant="pills">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">Bill Biweekly</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">Bill Monthly</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                </div>
                                <p className="tag">Save more monthly</p>
                            </div>
                            <div className="tabs-body">
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <div className="row">
                                            <div className="col-lg-3 col-md-6">
                                                <div className="pricing-card" data-aos="fade-up" data-aos-delay="100">
                                                    <div className="header">
                                                        <h3 className="medium plan">FREE</h3>
                                                        <p className="title">A simple start for everyone</p>
                                                        <h3 className="medium h3 price">0.00 ETH</h3>
                                                        <span className="month gray">Per month</span>
                                                    </div>
                                                    <ul className="list">
                                                        <li>Datacenter</li>
                                                        <li>Explorer</li>
                                                        <li>Decompiler</li>
                                                        <li>Access to leaderboards</li>
                                                        <li>1 day free trial fully unlocked</li>
                                                    </ul>
                                                    <a href={`https://t.me/${channelName + (referralId ? "?start=ref_" + referralId : "")}`} target="_blank" className="btn-style transparent hover-warning">GET STARTED</a>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="pricing-card" data-aos="fade-up" data-aos-delay="200">
                                                    <div className="header">
                                                        <h3 className="medium plan">PREMIUM</h3>
                                                        <p className="title">Best choice for degens</p>
                                                        <h3 className="medium h3 price">0.05 ETH</h3>
                                                        <span className="month gray">Per month</span>
                                                    </div>
                                                    <ul className="list">
                                                        <li>Volume Trader</li>
                                                        <li>Dashboard</li>
                                                        <li>1 Profile</li>
                                                        <li>1 Multi-profile Notifications</li>
                                                        <li>Access to leaderboards @ 0.25x</li>
                                                    </ul>
                                                    <a href={`https://t.me/${channelName + (referralId ? "?start=ref_" + referralId : "")}`} target="_blank" className="btn-style transparent hover-warning">GET STARTED</a>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="pricing-card" data-aos="fade-up" data-aos-delay="300">
                                                    <div className="header">
                                                        <h3 className="medium plan">ADVANCE  <span className="tag">Popular</span></h3>
                                                        <p className="title">For advanced traders</p>
                                                        <h3 className="medium h3 price">0.10 ETH</h3>
                                                        <span className="month gray">Per month</span>
                                                    </div>
                                                    <ul className="list">
                                                        <li>Exclusive Modules</li>
                                                        <li>3 Profiles</li>
                                                        <li>5 Multi-profile Notifications </li>
                                                        <li>Access to leaderboards @ 0.35x</li>
                                                    </ul>
                                                    <a href={`https://t.me/${channelName + (referralId ? "?start=ref_" + referralId : "")}`} target="_blank" className="btn-style transparent hover-warning">GET STARTED</a>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="pricing-card" data-aos="fade-up" data-aos-delay="400">
                                                    <div className="header">
                                                        <h3 className="medium plan">CHAD</h3>
                                                        <p className="title">Solution for professional traders</p>
                                                        <h3 className="medium h3 price">0.15 ETH</h3>
                                                        <span className="month gray">Per month</span>
                                                    </div>
                                                    <ul className="list">
                                                        <li>5 Profiles</li>
                                                        <li>10 Multi-profile Notifications </li>
                                                        <li>Access to Private groups</li>
                                                        <li>Dedicated Customer Care</li>
                                                        <li>Dedicated Feature requests</li>
                                                    </ul>
                                                    <a href="#" className="btn-style transparent disable">COMING SOON</a>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <div className="row">
                                            <div className="col-lg-3 col-md-6">
                                                <div className="pricing-card">
                                                    <div className="header">
                                                        <h3 className="medium plan">FREE</h3>
                                                        <p className="title">A simple start for everyone</p>
                                                        <h3 className="medium h3 price">0.00 ETH</h3>
                                                        <span className="month gray">Per month</span>
                                                    </div>
                                                    <ul className="list">
                                                        <li>Datacenter</li>
                                                        <li>Explorer</li>
                                                        <li>Decompiler</li>
                                                        <li>Access to leaderboards</li>
                                                        <li>1 day free trial fully unlocked</li>
                                                    </ul>
                                                    <a href={`https://t.me/${channelName + (referralId ? "?start=ref_" + referralId : "")}`} target="_blank" className="btn-style transparent">GET STARTED</a>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="pricing-card">
                                                    <div className="header">
                                                        <h3 className="medium plan">PREMIUM</h3>
                                                        <p className="title">Best choice for degens</p>
                                                        <h3 className="medium h3 price">0.04 ETH</h3>
                                                        <span className="month gray">Per month</span>
                                                    </div>
                                                    <ul className="list">
                                                        <li>Volume Trader</li>
                                                        <li>Dashboard</li>
                                                        <li>1 Profile</li>
                                                        <li>1 Multi-profile Notifications</li>
                                                        <li>Access to leaderboards @ 0.25x</li>
                                                    </ul>
                                                    <a href={`https://t.me/${channelName + (referralId ? "?start=ref_" + referralId : "")}`} target="_blank" className="btn-style transparent">START TRIAL</a>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="pricing-card">
                                                    <div className="header">
                                                        <h3 className="medium plan">ADVANCE  <span className="tag">Popular</span></h3>
                                                        <p className="title">For advanced traders</p>
                                                        <h3 className="medium h3 price">0.075 ETH</h3>
                                                        <span className="month gray">Per month</span>
                                                    </div>
                                                    <ul className="list">
                                                        <li>Exclusive Modules</li>
                                                        <li>3 Profiles</li>
                                                        <li>5 Multi-profile Notifications </li>
                                                        <li>Access to leaderboards @ 0.35x</li>
                                                    </ul>
                                                    <a href={`https://t.me/${channelName + (referralId ? "?start=ref_" + referralId : "")}`} target="_blank" className="btn-style transparent">GET STARTED</a>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="pricing-card">
                                                    <div className="header">
                                                        <h3 className="medium plan">CHAD</h3>
                                                        <p className="title">Solution for professional traders</p>
                                                        <h3 className="medium h3 price">0.125 ETH</h3>
                                                        <span className="month gray">Per month</span>
                                                    </div>
                                                    <ul className="list">
                                                        <li>5 Profiles</li>
                                                        <li>10 Multi-profile Notifications </li>
                                                        <li>Access to Private groups</li>
                                                        <li>Dedicated Customer Care</li>
                                                    </ul>
                                                    <a href="#" className="btn-style transparent disable">COMING SOON</a>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </Tab.Container>
                    </div>
                </div>
            </section>

            <section className="faq-question" data-aos="fade-up">
                <div className="container">
                    <h2>Frequestly asked questions</h2>
                    <p className="p">Here is a list of commonly asked questions and answers</p>
                    <div className="faq-wrapper">
                        <Accordion defaultActiveKey="0">
                            <OurFaq/>
                        </Accordion>
                    </div>
                </div>
            </section>

            <CryptoTrading/>

            <Footer/>

        </div>
    );
}

export default VolumeTracker;
