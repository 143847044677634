import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import Modal from 'react-bootstrap/Modal';

// Import Components
import Header from "../components/Header";
import Footer from "../components/Footer";
import CryptoTrading from "../components/CryptoTrading";
import BlogCard from "../components/BlogCard";
import ClientReviews from "../components/ClientReviews";
import YardTools from "../components/YardTools";

// Import Images
import playIcon from '../assets/images/icons/play-icon.svg'
import bannerImg from '../assets/images/home-banner-img.png'
import blockchainBotImg from '../assets/images/blochchain-bot-img.png'
import rightArrowIcon from '../assets/images/icons/right-arrow-yellow-icon.svg'


function Home() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <div className="home">

            <Modal show={show} className="video-modal" onHide={handleClose}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="body-wrapper">
                        <div className="video-wrapper">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/oh904_HdkwY?si=tElboBrenx3ZiXwH" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                        </div>
                    </div>
                </Modal.Body>
                {/*<Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Save Changes
                    </Button>
                </Modal.Footer>*/}
            </Modal>


            <Header/>

            <section className="banner">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 align-self-center">
                            <div className="content-wrapper">
                                <h1 data-aos="fade-up">The <span className="line">Ultimate</span> Trading Hub For Degens</h1>
                                <p className="large" data-aos="fade-up" data-aos-delay="100">Unleash the power of  our Trading  Bot with a unique set of tools and reign supreme in the world of crypto. Join us and conquer the markets!</p>
                                <ul className="flex-column flex-lg-row" data-aos="fade-up" data-aos-delay="200">
                                    <li><Link to="/volume-trader" className="btn-style">GET STARTED FOR FREE</Link></li>
                                    <li><a href="#" className="btn-style icon transparent"><img src={playIcon} alt="icon"/> Watch Video</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-6 align-self-center">
                            <div className="img-wrapper">
                                <img src={bannerImg} alt="img"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="yard-tool">
                <div className="container text-center mt-4 mt-lg-0">
                    <h2>Why YardTools?</h2>
                    <p className="p">YardTools is an indispensable part of any
                        crypto traders toolkit, able to constantly monitor <br/> every
                        token on the market with all the information one may need
                        in one place. Built by traders with years <br/> of experience</p>
                    <div className="yard-card-wrapper">
                       <YardTools/>
                    </div>
                </div>
            </section>

            <section className="blockchain-bot background-alt">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="img-wrapper">
                                <img src={blockchainBotImg} alt="img" data-aos="fade-right" data-aos-delay="100"/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="content-wrapper">
                                <h2 className="h2 pe-lg-4">Powerful blockchain bots for degens</h2>
                                <p className="p gray">Unleash the power of  our Trading
                                    Bot with a unique set of tools and reign  <br/>
                                    supreme in the world of crypto. Join us and conquer
                                    the markets!</p>
                                <div className="steps">
                                    <Link to="volume-trader">
                                        <h3 className="h3 large">Volume Trader</h3>
                                        <p>ETH volume trader scans on chain activities and enables users to track the flow of liquidity in real-time</p>
                                    </Link>
                                    <Link to="volume-trader">
                                        <h3 className="h3 large">Decompiler</h3>
                                        <p>A tool designed to detect each token released on the blockchain, revealing the contract's functions or simulating potential functions for unverified contracts.</p>
                                    </Link>
                                    <Link to="volume-trader">
                                        <h3 className="h3 large">Explorer</h3>
                                        <p>The Explorer bot serves as the paramount method for uncovering prospective data of a token. </p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="client-say">
                <div className="container" data-aos="fade-up">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="client-detail">
                                <h3 className="h3 yellow">What Our Customers Say</h3>
                                <h3 className="h3">The world's leading <br/> blockchain
                                    data and <br/> intelligence platform</h3>
                                <a href="#" className="yellow">See More <img src={rightArrowIcon} alt="icon"/></a>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <ClientReviews />
                        </div>
                    </div>
                </div>
            </section>
            <hr/>
            <section className="yard-blog">
                <div className="container">
                    <h2>Explore YardTools</h2>
                    <div className="row mt-5">
                        <BlogCard/>
                    </div>
                </div>
            </section>

            <CryptoTrading/>

            <Footer/>

        </div>
    );
}

export default Home;
