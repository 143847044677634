import React, {useState, useEffect} from 'react';


import authorImg from "../assets/images/author-img.png";
import authorImg2 from "../assets/images/author-img-2.png";
import authorImg3 from "../assets/images/author-img-3.png";
import Slider from "react-slick";

function ClientReviews() {


    const tagSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
    };

    const review = [
        {
            review: '"Tried all the tools out there as a pure degen.\n' +
                '                                            Yard tools was a miracle for a degen like me. It was exactly\n' +
                '                                            what I was looking for. Great features, amazing service."',
            clientImg: authorImg,
            clientName: 'Pingu',
            clientNiche: 'Degen Penguin',
        },
        {
            review: '"Yardtools has significantly made it easier to sort\n' +
                '                                            through newly launched tokens, wallets, and locked tokens better than\n' +
                '                                            any bot I’ve used before."',
            clientImg: authorImg2,
            clientName: 'Iguana',
            clientNiche: 'Just a degen',
        },
        {
            review: '"Yardtools has made me print multiple 100xs, 500xs, 1000xs and even over 2000xs. Insane tool if used with the right strategy and effective at catching high volume coins."',
            clientImg: authorImg3,
            clientName: 'S',
            clientNiche: 'Crypto degen',
        }
    ]

    return (
        <>
            <Slider {...tagSettings}>
                {
                    review.map((item, index)=>(
                        <div className="item" key={index}>
                            <div className="client-review">
                                <h2 className="h2 bold">{item.review}</h2>
                                <div className="author-wrapper">
                                    <img src={item.clientImg} alt="img"/>
                                    <div className="content">
                                        <p className="p bold">{item.clientName}</p>
                                        <p className="text-sm text-muted">{item.clientNiche}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </Slider>
        </>
    );
}

export default ClientReviews;
