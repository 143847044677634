import React, {useState, useEffect} from 'react';


// Import Components
import Header from "../components/Header";
import Footer from "../components/Footer";
import CryptoTrading from "../components/CryptoTrading";
import OurHistory from "../components/OurHistory";
import TeamMembers from "../components/TeamMembers";


// Import Images
import aboutBannerImg from '../assets/images/about-banner-img.png'
import {Link} from "react-router-dom";




function About() {
    useEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <div className="home">

            <Header/>

            <section className="banner inner-banner about-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-md-6 align-self-center">
                            <div className="content-wrapper">
                                <p className="tag" data-aos="fade-up">Gain Insight With</p>
                                <h2 data-aos="fade-up">About YardTools</h2>
                                <p className="large" data-aos="fade-up" data-aos-delay="100">Unleash your crypto trading potential today!</p>
                                <ul className="flex-column flex-lg-row" data-aos="fade-up" data-aos-delay="200">
                                    <li><Link to="/volume-trader" className="btn-style round">GET STARTED FOR FREE</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-7 col-md-6 align-self-center">
                            <div className="img-wrapper">
                                <img src={aboutBannerImg} alt="img"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="token-volume">
                <div className="container">
                    <ul className="bread-crumb">
                        <li><a href="#" className="large">Home</a></li>
                        <li><p>About us</p></li>
                    </ul>
                    <div className="content-wrapper">
                        <p className="gray">Welcome to a transformative journey through the world
                            of cryptocurrency trading with VolumeTrader. Born from the passion and
                            expertise of a group of seasoned traders, this tool was forged in the
                            fires of the financial markets. It's a heartfelt story of success, crafted
                            to help even the most novice traders thrive in the complex world of crypto.
                            Our goal is simple yet profound: to help you succeed, to make trading less
                            daunting, and to guide you towards financial wins that once seemed out of reach.</p>
                        <p className="gray">With VolumeTrader, you're not just using a tool; you're
                            harnessing a powerhouse of data and precision. It sifts through the noise
                            of the market with a fine-tooth comb, identifying the shining tokens amidst
                            the rough. This isn't just about making trades; it's about making smart,
                            informed decisions that put you ahead of the curve. It's about giving you
                            that gentle nudge towards tokens that carry the promise of prosperity, tokens
                            that others might have overlooked, but you won't.</p>
                        <p className="gray">We understand that trading can be overwhelming, that the market
                            waits for no one, and sometimes opportunities slip through your fingers. That's
                            where VolumeTrader steps in - it's your vigilant companion in this fast-paced arena.
                            With its alerts and detailed insights, it ensures that you're equipped to make swift,
                            effective decisions. This tool is more than just an algorithm; it's your ticket to
                            confidently navigate the tides of trading and sail towards a future of abundant gains.
                            Together, we're breaking down barriers, simplifying the complex, and paving your path
                            to financial triumph.</p>
                    </div>
                </div>
            </section>

            <section className="our-milestones background-alt">
                <div className="container">
                    <h2><span className="line">Our History</span></h2>
                    <ul className="milestones-steps">
                        <OurHistory/>
                    </ul>
                </div>
            </section>

            <section className="team-members" id="team">
                <div className="container">
                    <h2>Our Team</h2>
                    <p className="p">We’re a growing team od blockchain experts and
                        thought leaders - full of personality <br/> minus the ego.
                        We’re of many hats dedicated to bring crypto to the masses</p>
                    <div className="row">
                       <TeamMembers/>
                    </div>
                </div>
            </section>

            <CryptoTrading/>

            <Footer/>

        </div>
    );
}

export default About;
