import React, {useState, useEffect} from 'react';

function OurHistory() {

    const history = [
        {
            date: "November 2022",
            title: 'Inception',
            desc: 'In November, we introduced the Volume Trader Arch, which brought the most powerful gem-finding tool to the degenerate space. It was a game-changer for all traders!'
        },
        {
            date: "April 2023",
            title: 'Explorers',
            desc: 'Then, in April 2023, we unveiled the Explorer Arch. We created the most accurate and lightning-fast bot for fetching token breakdowns, and the best part? It was available for free to everyone in their groups. Sharing the knowledge!'
        },
        {
            date: "July 2023",
            title: 'Monopoly',
            desc: 'Fast forward to July 2023, and we took the entire market by storm. Our name, YardTools, became synonymous with success in every group chat out there. We\'ve held the throne ever since, and our reputation speaks for itself.'
        },
        {
            date: "November  2023",
            title: 'Reforging',
            desc: 'We embarked on our biggest journey yet - the Volume Trader revamp. It was our most significant reboot since our very first launch. While we may have taken a breather in the past year, we came back stronger than ever, ready to continue our mission of empowering traders everywhere.'
        }
    ]

    return (
        <>
            {
                history.map((item, index)=>(
                    <li>
                        <div className="milestone-card" key={index} data-aos="fade-up" data-aos-delay={(index + 1) * 100}>
                            <p className="medium">{item.date}</p>
                            <h3 className="h3">{item.title}</h3>
                            <p>{item.desc}</p>
                        </div>
                    </li>
                ))
            }
        </>
    );
}

export default OurHistory;
