import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";

import footerLogo from "../assets/images/footer-logo.svg";
import telegramIcon from "../assets/images/icons/telegram-icon.svg";
import twitterIcon from "../assets/images/icons/twitter-icon.svg";
import mediumIcon from "../assets/images/icons/medium-icon.svg";


function Footer() {

    return (
        <>
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-4" data-aos="fade-up">
                            <div className="logo-wrapper">
                                <a href="#" className="logo">
                                    <img src={footerLogo} alt="logo"/>
                                </a>
                                <p>YardTools was founded in 2022. Back then volume trader it could only monitor volume with no filters on the Ethereum network. The project has since then has evolved into a gem finding machine.</p>
                                <ul className="social">
                                    <li><a href="https://t.me/YardTools" target="_blank"><img src={telegramIcon} alt="icon"/></a></li>
                                    <li><a href="https://twitter.com/THEYARDTOOLS" target="_blank"><img src={twitterIcon} alt="icon"/></a></li>
                                    <li><a href="https://medium.com/@theyardfoundation" target="_blank"><img src={mediumIcon} alt="icon"/></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-8" data-aos="fade-up">
                            <div className="menu-wrapper">
                                <div className="menu" data-aos="fade-up" data-aos-delay="100">
                                    <h2 className="p title">Products</h2>
                                    <ul className="menu">
                                        <li><Link to="/volume-trader">Volume Trader</Link></li>
                                        <li><Link to="/volume-trader">Decompiler</Link></li>
                                        <li><Link to="/volume-trader">Explorer</Link></li>
                                    </ul>
                                </div>
                                <div className="menu" data-aos="fade-up" data-aos-delay="200">
                                    <h2 className="p title">Socials</h2>
                                    <ul className="menu">
                                        <li><a href="https://t.me/YardTools" target="_blank">Telegram</a></li>
                                        <li><a href="https://twitter.com/THEYARDTOOLS" target="_blank">Twitter</a></li>
                                        <li><a href="https://medium.com/@theyardfoundation" target="_blank">Medium</a></li>
                                    </ul>
                                </div>
                                <div className="menu" data-aos="fade-up" data-aos-delay="300">
                                    <h2 className="p title">Help</h2>
                                    <ul className="menu">
                                        <li><a href="#">Privacy Policy</a></li>
                                        <li><a href="#">Terms of use</a></li>
                                    </ul>
                                </div>
                                <div className="menu" data-aos="fade-up" data-aos-delay="400">
                                    <h2 className="p title">Join us</h2>
                                    <ul className="menu">
                                        <li>Keep in touch with us for <br/>
                                            more updated information</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;
