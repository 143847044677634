import React, {useState, useEffect} from 'react';



function CryptoTrading() {

    return (
        <>
            <section className="elevate-crypto background-alt">
                <div className="container">
                    <div className="content-wrapper">
                        <h2 className="h2" data-aos="fade-up">Elevate your crypto <br/>
                            trading to the next level</h2>
                        <p data-aos="fade-up" data-aos-delay="100">Our mission is to demystify token data, making it as straightforward, dependable, and precise as possible. Even for the most disoriented users.</p>
                        <a href="https://t.me/YardTools" className="btn-style" data-aos="fade-up" data-aos-delay="200">JOIN TELEGRAM</a>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CryptoTrading;
