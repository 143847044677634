import React, {useState, useEffect} from 'react';
import Accordion from "react-bootstrap/Accordion";

function OurFaq() {

    const faq = [
        {
            title: 'Do you need Telegram to use the bot?',
            desc: 'While there is no permanent free version of YardTools, we\n' +
                '                                    do offer new and curious users a free trial where they are able to use\n' +
                '                                    the bot for a set period. After this period, users will be required to\n' +
                '                                    subscribe in order to keep using the bot.'
        },
        {
            title: 'Does YardTools have a free version?',
            desc: 'While there is no permanent free version of YardTools, we do offer new and curious users a free trial where they are able to use the bot for a set period. After this period, users will be required to subscribe in order to keep using the bot.'
        },
        {
            title: 'How do I actually use the bot?',
            desc: 'Regarding use of the bot and what the terms used mean, please see (user manual link) for all your answers and more.'
        },
        {
            title: 'What types of payment do you accept?',
            desc: 'Currently only cryptocurrency is able to be used for payments, however there are plans to be able to use fiat in the future. The crypto’s accepted for payment are Ethereum (ETH) and BNB.'
        },
        {
            title: 'Where can I ask for help or suggest feedback for the bot?',
            desc: 'The main forum for discussion of the bot and to receive support is the Telegram group (https://t.me/YardTools). Here we have active mods who are ready to respond to any requests or queries to ensure all users feel happy with their service.'
        },
    ]

    return (
        <>
            {
                faq.map((item, index)=>(
                    <Accordion.Item eventKey={index}>
                        <Accordion.Header>{item.title}</Accordion.Header>
                        <Accordion.Body>{item.desc}</Accordion.Body>
                    </Accordion.Item>
                ))
            }
        </>
    );
}

export default OurFaq;
