import React, {useState, useEffect} from 'react';
import memeberImg1 from "../assets/images/member-img-1.png";
import memeberImg2 from "../assets/images/member-img-2.png";
import memeberImg3 from "../assets/images/member-img-3.png";
import memeberImg4 from "../assets/images/member-img-4.png";
import SocialIcon1 from "../assets/images/icons/telegram-team-icon.svg";
import SocialIcon2 from "../assets/images/icons/twitter-team-icon.svg";
import SocialIcon3 from "../assets/images/icons/mail-team-icon.svg";

function TeamMembers() {

    const members = [
        {
            img: memeberImg1,
            name: 'Boudach',
            position: 'Founder and CEO',
            telegram: 'https://t.me/boudach'
        },
        {
            img: memeberImg2,
            name: 'Montivado',
            position: 'Founder and CEO',
            telegram: 'https://t.me/Montivado'
        },
        {
            img: memeberImg3,
            name: 'kacper',
            position: 'Founder and CEO',
            telegram: 'https://t.me/cockper'
        },
        {
            img: memeberImg4,
            name: 'Optimus Prime',
            position: 'Founder and CEO',
            telegram: 'https://t.me/OptimusPrimeD3v2'
        },
    ]

    return (
        <>
            {
                members.map((item, index)=>(
                    <div className="col-lg-3 col-md-6" key={index} data-aos="fade-up" data-aos-delay={(index + 1) * 100}>
                        <div className="team-card">
                            <div className="img-wrapper">
                                <img src={item.img} alt="img"/>
                            </div>
                            <div className="content-wrapper">
                                <h3 className="h3">{item.name}</h3>
                                <p>{item.position}</p>
                                <ul className="social">
                                    <li><a href={item.telegram} target="_blank"><img src={SocialIcon1} alt="icon"/></a></li>
                                    <li><a href="#"><img src={SocialIcon2} alt="icon"/></a></li>
                                    <li><a href="#"><img src={SocialIcon3} alt="icon"/></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                ))
            }
        </>
    );
}

export default TeamMembers;
