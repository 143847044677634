import React, {useState, useEffect} from 'react';
import blogImg1 from "../assets/images/explore-twitter.png";
import blogImg2 from "../assets/images/explore-medium.png";
import blogImg3 from "../assets/images/explore-docs.png";

function OurBlog() {

    const blog = [
        {
            img: blogImg1,
            title: 'Follow us on twitter',
            desc: 'Follow us on twitter to stay up to date',
            url: 'https://twitter.com/THEYARDTOOLS'
        },
        {
            img: blogImg2,
            title: 'Check out our Medium',
            desc: 'Find latest articles published on on our medium page',
            url: 'https://medium.com/@theyardtools'
        },
        {
            img: blogImg3,
            title: 'Handbook',
            desc: 'Find in depth documentation on yardtools bots and services',
            url: 'https://yardtools.gitbook.io/suite/'
        }
    ]

    return (
        <>
            {
                blog.map((item, index)=>(
                    <div className="col-md-4 my-4" key={index} data-aos="fade-up" data-aos-delay={(index + 1) * 100}>
                        <div className="blog-card h-100">
                            <div className="img-wrapper">
                                <img src={item.img} alt="img"/>
                            </div>
                            <a href={item.url} target="_blank" className="content-wrapper">
                                <h3 className="h3 medium">{item.title}</h3>
                                <p>{item.desc}</p>
                            </a>
                        </div>
                    </div>
                ))
            }
        </>
    );
}

export default OurBlog;
